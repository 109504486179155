import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { AppConfigProvider } from '@rugby-au/app-config';
import { clearCart, getCart, getUser, setCart as setAsyncSetCart } from '@rugby-au/services';
import { getTheme, ThemeProvider } from '@rugby-au/theme';
import { wallabiesHeader, WebHeaderProps } from '@rugby-au/web-header';
import { NotificationBar } from '@rugby-au/notification-bar';

import '../styles/globals.css';
import 'react-datepicker/dist/react-datepicker.css';

import { CartProps } from '@rugby-au/services';
import { siteColors } from '../configs/siteTheme';
import { setCredentials } from '@rugby-au/services/src/rx/auth/user';

export default function App({ Component, pageProps }: AppProps) {
  const theme = getTheme({ siteColors });
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const [header, setHeader] = useState<WebHeaderProps>({});
  const [cart, setCart] = useState<CartProps>({ items: [], meta: {} });

  useEffect(() => {
    const handleHeader = () => {
      const _header = {
        ...wallabiesHeader,
        primaryNav: {
          ...wallabiesHeader.primaryNav,
          navItems:
            wallabiesHeader.primaryNav.navItems?.map(item => {
              return {
                ...item,
                ...(item.href && item.href !== '#' && item.href.startsWith('/') && !item.stopTranform ? { href: `https://wallabies.rugby${item.href}` } : {}),
                subNav: {
                  ...item.subNav,
                  navItems: item?.subNav?.navItems.map(subItem => {
                    return { ...subItem, ...(subItem.href && subItem.href !== '#' && subItem.href.startsWith('/') ? { href: `https://wallabies.rugby${subItem.href}` } : {}) };
                  }),
                },
              };
            }) ?? [],
        },
      };

      setHeader(_header as WebHeaderProps);
    };

    const handleCart = async () => {
      const _cart = await getCart();
      //Clear  cart if expired in loading the app
      const currentTime = new Date().getTime();
      if (_cart && _cart.items && _cart.meta.cartExpiry && _cart.meta.cartExpiry > currentTime) {
        setCart(_cart as CartProps);
      } else {
        await clearCart();
        const newCart = { items: [], meta: {} };
        setCart(newCart);
        setAsyncSetCart(newCart);
      }
    };
    const handleLoggedIn = async () => {
      const user = await getUser();
      if (user?.userId) {
        setIsLogged(true);
      }
    };

    handleLoggedIn();
    handleHeader();
    handleCart();
  }, []);

  const router = useRouter();
  useEffect(() => {
    if (isLogged === false) {
      setCredentials(undefined);
      const logoutUrl = `${process.env.NEXT_PUBLIC_AUTH_URL}logout?clientId=memberships`;
      window.location.href = logoutUrl;
    }
  }, [isLogged]);

  return (
    <AppConfigProvider
      appConfig={{
        setNavigation: (path: string) => {
          router.push(path);
        },
        appName: 'rugby-membership',
        header,
        setHeader,
        isLogged,
        setIsLogged,
        cart,
        setCart,
      }}
      defaultNationalId={'RAU'}>
      <ThemeProvider theme={theme}>
        <NotificationBar />
        <Component {...pageProps} />
      </ThemeProvider>
    </AppConfigProvider>
  );
}
