import { CalculateMembershipReturnProps } from '@rugby-au/services';
import { BillData } from '../Bill';

/**
 * Converts the response from calculate membership to BillData
 * @param calculateResponse
 * @returns
 */
export const getBillFromCalculateResponse = (calculateResponse: CalculateMembershipReturnProps): BillData | null => {
  try {
    if (calculateResponse && calculateResponse.billDetail) {
      return {
        billDetail: calculateResponse.billDetail.map(product => ({
          id: product.id,
          name: product.name,
          price: product.price,
          // autoRenew: cart.find(item => item.id === product.id)?.autoRenew ?? false, // Since API response doenst return autoRenew value, we need to get it from cart
          autoRenew: false, //Auto renew is not supported in this version
          items: product.types,
        })),
        subtotal: calculateResponse.subTotal,
        totalDiscount: 0,
        donation: 0,
        total: calculateResponse.total,
        processingFee: calculateResponse.processingFee,
        processingPerc: calculateResponse.processingPerc,
      };
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};
